import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import { GatsbyImageProps } from "gatsby-image";
import * as React from "react";
import Styled from "styled-components/macro";
import Button from "../../components/buttons/Button";
import LandingPageSection, { LandingPageProps } from "../../components/LandingPageSection";
import Content from "../../components/layout/Content/Content";
import Layout from "../../components/layout/Layout";
import Meta from "../../components/meta";
import BlogHighlight from "../../components/pages/blog/BlogHighlight";
import Shoutout from "../../components/pages/signup/Shoutout";
import FourCards from "../../components/screenshot/FourCards";
import SectionWithLeftVideoDark from "../../components/SectionWithLeftVideoDark";
import Description from "../../components/texts/Description";
import Headline from "../../components/texts/Headline";
import RichDescriptionExtended from "../../components/texts/RichDescription";

import blogThumbnail1 from "../../components/pages/blog/BlogThumbnails/digitize_your_hand_drawn_wireframes.webp";
import blogThumbnail2 from "../../components/pages/blog/BlogThumbnails/BLOG_AIFeatures_Blog.webp";
import blogThumbnail3 from "../../components/pages/blog/BlogThumbnails/UZ_BLOG_230213_ExpertContent_Header.webp";
import SectionWithRightVideo from "../../components/pages/solutions/SectionWithRightVideo";
import YoutubeEmbed from "../../components/layout/YoutubeEmbed/YoutubeEmbed";

const ContentExtended = Styled(Content)`
  margin-top: 9rem;
`;
const Column = Styled.div`
  position: relative;
  
  ${Media.lessThan("small")`
    &:last-of-type {
      width: 100%;
    }
  `}
  
  ${Media.lessThan("medium")`
    width: 50%;
    
    &:first-of-type {
      margin-right: 3rem;
    }
    
    &:last-of-type {
      width: 32rem;
    }
    `}
   
  ${Media.lessThan("660px")`
    width: 100%;
    margin: 0 auto;
    
    &:last-of-type {
      width: 100%;
    }
  `}
  
  ${Media.greaterThan("medium")`
    width: 45%;
    min-width: 0;
    padding: 0 3rem;
  `}

  ${Media.greaterThan("large")`
    padding: 0 6rem;
  `}
`;

const ContentIntro = Styled(Content)`
    margin-bottom: 10rem;
    
    ${Media.greaterThan("medium")`
      margin-bottom: 0;
  `}
`;

const Container = Styled.div`
  width: 100%;
  text-align: center;
  margin: 16rem 0 10rem 0;
  padding: 9rem;
  
  ${Media.lessThan("large")`
    padding: 0 4rem;
  `}
  
  ${Media.lessThan("medium")`
      margin: 11rem 0 5rem 0;
  `}
  
  ${Media.lessThan("small")`
    padding: 0;
    margin: 9rem 0 4rem 0;
  `}
`;

const ExtendedDescription = Styled(Description)`
    max-width: 120rem;
    margin: auto;
`;


const screenshotIndexPage = ({ data }) => {
  return (
    <>
      <Layout type="dark">
        <Meta
          title="Uizard Wireframe | Digitize Your Wireframe Sketches | Uizard"
          description="Scan in your hand-drawn wireframes and transform them into digital, editable mockups in no time at all. Sign up and try for free!"
          url="/wireframe-scanner/" />

        <LandingPageSection
          headline="Uizard Wireframe"
          imageA={data.wireframeScannerConversionA.childImageSharp}
          imageB={data.wireframeScannerConversionB.childImageSharp}
          description="Scan your wireframe drawings and convert them into editable mockups with the power of AI."
          videoName="uizard-wireframe-scanner"
          imageAlt="how a user can use Uizard to turn a hand-drawn wireframe sketch into a digital design mockup"
          videoH={500}
          />

        <ContentIntro>
          <Container>
            <Headline>
              From wireframe drawing to digital design
            </Headline>
            <RichDescriptionExtended dark content={"Whether you've sketched your ideas on paper or scribbled them down on a whiteboard, Uizard AI can turn your sketches into editable mockups in a matter of seconds. Ideation and iteration just got a whole lot faster."} />
          </Container>
        </ContentIntro>

        <SectionWithRightVideo
          headline="Digitize your designs in seconds"
          description="Bring your hand-drawn concepts to life in no time at all. With Uizard's AI features and drag-and-drop editor, going from design idea to high-fidelity, clickable prototype has never been easier."
          imageA={data.wireframeScanImageA.childImageSharp}
          imageB={data.wireframeScanImageB.childImageSharp}
          imageAlt="turn hand-drawn sketches into beautiful designs automatically."
          videoName="ai-landing-scanner"
        />
        
        <SectionWithLeftVideoDark
          headline="Switch fidelity with Wireframe Mode"
          description="Once you've scanned in your hand-drawn wireframes, you can easily switch between mockup and basic wireframe with Uizard's Wireframe Mode!"
          description2="Switch from high to low fidelity in an instant with the click of a button."
          imageA={data.wireframeModeBig.childImageSharp}
          imageB={data.wireframeModeSmall.childImageSharp}
          videoName="uizard-wireframe-high-fi-mode-dark"
          imageAlt="a demo of wireframe mode to render a UI design into high-fidelity or low-fidelity"
          link=""
          videoH={750}
        />

        <YoutubeEmbed
          videoW={1220}
          videoH={686}
          url="https://www.youtube.com/embed/q8vY-yXrEh8"
        />

        <BlogHighlight
          title="Learn more about AI design"
          blogs={[
            {
              image: blogThumbnail1,
              link: "https://uizard.io/blog/digitize-your-hand-drawn-wireframes-with-ai/",
              alt: "Thumbnail for blog titled How to digitize your hand-drawn wireframes with AI",
            },
            {
              image: blogThumbnail2,
              link: "https://uizard.io/blog/a-complete-guide-to-uizards-ai-features/",
              alt: "Thumbnail for blog titled A complete guide to Uizard's AI design features",
            },
            {
              image: blogThumbnail3,
              link: "https://uizard.io/blog/uizard-screenshot-convert-screenshots-to-mockups/",
              alt: "Thumbnail for blog titled Uizard Screenshot: Convert screenshots to editable mockups with AI",
            },
          ]}
        />
        <ContentExtended>
          <Shoutout
            dark
            headline="From sketch to wireframe"
            description="Transform your workflow with Uizard today"
            cta="Sign up for free"
            imageA={data.shoutoutImageA.childImageSharp} />
        </ContentExtended>
      </Layout>
    </>
  )

};

export default screenshotIndexPage


export const data = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeScannerConversionA: file(
      relativePath: { eq: "wireframe-scanner/Wireframe_LaptopCover_Big.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }


    wireframeScannerConversionB: file(
      relativePath: { eq: "wireframe-scanner/Wireframe_LaptopCover_Small.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeScanImageA: file(relativePath: { eq: "ai/uizard-scanner-dark-big.png" }) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeScanImageB: file(relativePath: { eq: "ai/uizard-scanner-dark-small.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeModeBig: file(
      relativePath: { eq: "wireframe-scanner/Wire_DrawingToWireframe_Dark_Big.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeModeSmall: file(
      relativePath: { eq: "wireframe-scanner/Wire_DrawingToWireframe_Dark_Small.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    modularA: file(relativePath: { eq: "screenshot/ModularBuild.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

  }
`;
